import React, { useEffect, useRef, useState } from 'react';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { cssVar, getNextTimestamp } from '@/lib/utils';
import { useSelector } from 'react-redux';
import Card from '@/components/Card';
import { selectTheme } from 'store/appSlice';
import _ from 'lodash';
import { selectSelectedCriteriaForAnalysis } from 'store/analysisSlice';
import { getBidAsk } from '@/lib/stock';
import { TIME } from '@/lib/utils/constants';
import { Box } from '@mui/system';
import { Icon, IconButton } from '@mui/material';
import { Pause, PlayArrow } from '@mui/icons-material';
import { subscribeForQuotes, unsubscribeFromQuotes } from '@/lib/stream';
import { selectStreamRawQuotes } from 'store/streamSlice';

// Register necessary components for the bar chart
Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

const BidAskScatterBubble = ({ chartConfig }) => {
  const { symbol } = chartConfig;
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [realTime, setRealTime] = useState(false);
  const analysisCriteria = useSelector(selectSelectedCriteriaForAnalysis);
  const realTimeQuotes = useSelector(selectStreamRawQuotes);
  const appTheme = useSelector(selectTheme);
  const UP_COLOR = cssVar('--price-move-up-text');
  const DOWN_COLOR = cssVar('--price-move-down-text');
  const ticksColor = cssVar('--text-primary');

  const handleRealTimeToggle = () => {
    setRealTime(!realTime);
  };

  // Update the chart theme
  useEffect(() => {
    const chart = chartInstance.current;
    // Check if the chart instance is available
    if (!chart) {
      return;
    }

    // Update the chart options
    const chartOptions = _.cloneDeep(chart.options);

    chart.options = {
      ...chartOptions, // Merge existing options
      // Apply new options
      scales: {
        x: {
          title: {
            color: ticksColor,
          },
          ticks: {
            color: ticksColor, // Change the y-axis label color
          },
        },
        y: {
          title: {
            color: ticksColor,
          },
          ticks: {
            color: ticksColor, // Change the y-axis label color
          },
        },
      },
    };

    // Apply the changes to the chart
    chart?.update('none');
  }, [appTheme]);

  useEffect(() => {
    if (symbol) {
      let { from: _from } = analysisCriteria;
      const from = getNextTimestamp(_from, -5, TIME.MINUTES);
      const to = getNextTimestamp(_from, 1, TIME.MINUTES);
      if (from && to && !realTime) {
        getBidAsk(symbol, from, to).then((data) => {
          const bids = [];
          const asks = [];
          let maxSize = 0;

          // aggreagate Bids Data
          const bidsDataByTimestamp = {};

          data.forEach((d) => {
            const timestamp =
              parseInt(new Date(d.Timestamp).getTime() / 1000) * 1000;

            if (bidsDataByTimestamp[timestamp]) {
              if (bidsDataByTimestamp[timestamp][d.BidPrice]) {
                bidsDataByTimestamp[timestamp][d.BidPrice] += d.BidSize;
              } else {
                bidsDataByTimestamp[timestamp][d.BidPrice] = d.BidSize;
              }
            } else {
              bidsDataByTimestamp[timestamp] = {
                [d.BidPrice]: d.BidSize,
                Timestamp: d.Timestamp,
              };
            }
          });

          // aggreagate Asks Data
          const asksDataByTimestamp = {};

          data.forEach((d) => {
            const timestamp = new Date(d.Timestamp).getTime();

            if (asksDataByTimestamp[timestamp]) {
              if (asksDataByTimestamp[timestamp][d.AskPrice]) {
                asksDataByTimestamp[timestamp][d.AskPrice] += d.AskSize;
              } else {
                asksDataByTimestamp[timestamp][d.AskPrice] = d.AskSize;
              }
            } else {
              asksDataByTimestamp[timestamp] = {
                [d.AskPrice]: d.AskSize,
                Timestamp: d.Timestamp,
              };
            }
          });

          // Formatted Bids Data
          let formattedBidsData = [];
          Object.keys(bidsDataByTimestamp).forEach((t) => {
            const value = bidsDataByTimestamp[t];

            const timestamp = value.Timestamp;

            delete value.Timestamp;

            formattedBidsData = [
              ...formattedBidsData,
              ...Object.keys(value).map((price) => ({
                BidPrice: price,
                BidSize: value[price],
                Timestamp: timestamp,
              })),
            ];

            maxSize = Math.max(
              maxSize,
              ...formattedBidsData.map((d) => d.BidSize)
            );
          });

          // Formatted Asks Data
          let formattedAsksData = [];
          Object.keys(asksDataByTimestamp).forEach((t) => {
            const value = asksDataByTimestamp[t];

            const timestamp = value.Timestamp;

            delete value.Timestamp;

            formattedAsksData = [
              ...formattedAsksData,
              ...Object.keys(value).map((price) => ({
                AskPrice: price,
                AskSize: value[price],
                Timestamp: timestamp,
              })),
            ];

            maxSize = Math.max(
              maxSize,
              ...formattedAsksData.map((d) => d.AskSize)
            );
          });

          Object.values(formattedBidsData).forEach((d) => {
            bids.push({
              x: d.Timestamp,
              y: d.BidPrice,
              r: (d.BidSize / maxSize) * 50,
              size: d.BidSize,
            });
          });
          Object.values(formattedAsksData).forEach((d) => {
            asks.push({
              x: d.Timestamp,
              y: d.AskPrice,
              r: (d.AskSize / maxSize) * 50,
              size: d.AskSize,
            });
          });

          const chart = chartInstance.current;
          if (chart) {
            const data = chart.data;
            data.datasets = [
              {
                label: 'Bids',
                data: bids,
                backgroundColor: UP_COLOR,
              },
              {
                label: 'Asks',
                data: asks,
                backgroundColor: DOWN_COLOR,
              },
            ];
            // Remove old data to maintain a fixed window of 50 data points
            chart.update('none'); // Update chart without animations
          }
        });
      } else {
        const chart = chartInstance.current;
        if (chart) {
          const data = chart.data;
          data.datasets = [
            {
              label: 'Bids',
              data: [],
              backgroundColor: UP_COLOR,
            },
            {
              label: 'Asks',
              data: [],
              backgroundColor: DOWN_COLOR,
            },
          ];
          // Remove old data to maintain a fixed window of 50 data points
          chart.update('none'); // Update chart without animations
        }
      }
    }
  }, [JSON.stringify(analysisCriteria), symbol, realTime]);

  useEffect(() => {
    if (realTime) {
      symbol && subscribeForQuotes([symbol]);
    } else {
      const chart = chartInstance.current;
      if (chart) {
        const data = chart.data;
        data.datasets = [
          {
            label: 'Bids',
            data: [],
            backgroundColor: UP_COLOR,
          },
          {
            label: 'Asks',
            data: [],
            backgroundColor: DOWN_COLOR,
          },
        ];
        // Remove old data to maintain a fixed window of 50 data points
        chart.update('none'); // Update chart without animations
      }
      symbol && unsubscribeFromQuotes([symbol]);
    }
  }, [realTime]);

  //Update bubble chart with realtime quotes
  useEffect(() => {
    if (realTime) {
      const chart = chartInstance.current;
      const filteredQuotes = realTimeQuotes.filter(
        (quote) => quote.Symbol === symbol
      );

      if (chart) {
        const data = chart.data;
        const bids = data?.datasets?.[0]?.data || [];
        const asks = data?.datasets?.[1]?.data || [];

        let maxSize = 0;

        // aggreagate Bids Data
        const bidsDataByTimestamp = {};

        [...bids, ...filteredQuotes]
          .filter((d) => {
            //filter timestamp older than 1 minutes
            return (
              Date.now() - new Date(d.Timestamp || d.x).getTime() <
              1 * 60 * 1000
            );
          })
          .forEach((d) => {
            const timestamp = new Date(d.Timestamp || d.x).getTime();
            const price = d.BidPrice || d.y;
            const size = d.BidSize || d.size;

            if (bidsDataByTimestamp[timestamp]) {
              if (bidsDataByTimestamp[timestamp][price]) {
                bidsDataByTimestamp[timestamp][price] += size;
              } else {
                bidsDataByTimestamp[timestamp][price] = size;
              }
            } else {
              bidsDataByTimestamp[timestamp] = {
                [price]: size,
                Timestamp: timestamp,
              };
            }
          });

        // aggreagate Asks Data
        const asksDataByTimestamp = {};

        [...asks, ...filteredQuotes]
          .filter((d) => {
            //filter timestamp older than 1 minutes
            return (
              Date.now() - new Date(d.Timestamp || d.x).getTime() <
              1 * 60 * 1000
            );
          })
          .forEach((d) => {
            const timestamp = new Date(d.Timestamp || d.x).getTime();
            const price = d.AskPrice || d.y;
            const size = d.AskSize || d.size;

            if (asksDataByTimestamp[timestamp]) {
              if (asksDataByTimestamp[timestamp][price]) {
                asksDataByTimestamp[timestamp][price] += size;
              } else {
                asksDataByTimestamp[timestamp][price] = size;
              }
            } else {
              asksDataByTimestamp[timestamp] = {
                [price]: size,
                Timestamp: timestamp,
              };
            }
          });

        // Formatted Bids Data
        let formattedBidsData = [];
        Object.keys(bidsDataByTimestamp).forEach((t) => {
          const value = bidsDataByTimestamp[t];

          const timestamp = value.Timestamp;

          delete value.Timestamp;

          formattedBidsData = [
            ...formattedBidsData,
            ...Object.keys(value).map((price) => ({
              BidPrice: price,
              BidSize: value[price],
              Timestamp: timestamp,
            })),
          ];

          maxSize = Math.max(
            maxSize,
            ...formattedBidsData.map((d) => d.BidSize)
          );
        });

        // Formatted Asks Data
        let formattedAsksData = [];
        Object.keys(asksDataByTimestamp).forEach((t) => {
          const value = asksDataByTimestamp[t];

          const timestamp = value.Timestamp;

          delete value.Timestamp;

          formattedAsksData = [
            ...formattedAsksData,
            ...Object.keys(value).map((price) => ({
              AskPrice: price,
              AskSize: value[price],
              Timestamp: timestamp,
            })),
          ];

          maxSize = Math.max(
            maxSize,
            ...formattedAsksData.map((d) => d.AskSize)
          );
        });

        const _bids = [];
        const _asks = [];

        Object.values(formattedBidsData).forEach((d) => {
          _bids.push({
            x: d.Timestamp,
            y: d.BidPrice,
            r: (d.BidSize / maxSize) * 50,
            size: d.BidSize,
          });
        });

        Object.values(formattedAsksData).forEach((d) => {
          _asks.push({
            x: d.Timestamp,
            y: d.AskPrice,
            r: (d.AskSize / maxSize) * 50,
            size: d.AskSize,
          });
        });

        data.datasets = [
          {
            label: 'Bids',
            data: _bids,
            backgroundColor: UP_COLOR,
          },
          {
            label: 'Asks',
            data: _asks,
            backgroundColor: DOWN_COLOR,
          },
        ];
        chart.update('none'); // Update chart without animations
      }
    }
  }, [realTimeQuotes]);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Initialize Chart
    chartInstance.current = new Chart(ctx, {
      type: 'bubble',
      data: {
        labels: [], // Dynamic labels for timestamps
        datasets: [
          {
            label: 'Bids',
            data: [],
            backgroundColor: UP_COLOR,
          },
          {
            label: 'Asks',
            data: [],
            backgroundColor: DOWN_COLOR,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'timeseries',
            position: 'bottom',
            title: {
              display: true,
              text: 'Time',
              color: ticksColor,
            },
            ticks: {
              color: ticksColor, // Change the y-axis label color
            },
          },
          y: {
            title: {
              display: true,
              text: 'Price',
              color: ticksColor,
            },
            beginAtZero: false,
            ticks: {
              autoSkip: false, // Ensure all labels are shown
              color: ticksColor, // Change the y-axis label color
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (tooltipItems) => {
                // Customize the title
                return ``;
              },
              label: (tooltipItem) => {
                // Customize the label
                const { y, size } = tooltipItem.raw;
                return `Price: ${y}, Size: ${size}`;
              },
              afterLabel: (tooltipItem) => {
                // Add additional information after the label
                return '';
              },
            },
          },
        },
      },
    });

    // Cleanup on unmount
    return () => {
      chartInstance.current.destroy();
    };
  }, []);

  return (
    <Card sx={{ height: '100%', width: '100%', p: 1, position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 1,
          position: 'absolute',
          right: 0,
          top: 1,
        }}
      >
        <IconButton
          size="small"
          color="primary"
          onClick={handleRealTimeToggle}
          sx={{ mr: 1 }}
          disabled={!symbol}
        >
          {realTime ? <Pause /> : <PlayArrow />}
        </IconButton>
      </Box>
      <canvas style={{ height: '100%', width: '100%' }} ref={chartRef}></canvas>
    </Card>
  );
};

export default BidAskScatterBubble;
