import React, { useCallback, useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { Typography, Box } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector } from 'react-redux';
import { selectSelectedCriteriaForAnalysis } from 'store/analysisSlice';
import { getNextTimestamp, timeHHMMSS } from '@/lib/utils';
import { TIME } from '@/lib/utils/constants';
import { getTrades } from '@/lib/stock';

const TradesAnalyzer = ({ chartConfig }) => {
  const { symbol } = chartConfig;
  const [rows, setRows] = useState([]);
  const analysisCriteria = useSelector(selectSelectedCriteriaForAnalysis);

  const VirtualizedRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      return (
        <Box sx={style} display="flex" alignItems="center" padding={1}>
          {/* <Typography sx={{ width: '200px' }}>{row.time}</Typography> */}
          <Typography sx={{ width: '200px' }}>{row.price}</Typography>
          <Typography sx={{ width: '200px' }}>{row.size}</Typography>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            {row?.conditions?.map((condition) => (
              //mui chip
              <Box
                sx={{
                  padding: '0.25rem',
                  backgroundColor: 'var(--active-bg)',
                  borderRadius: '0.25rem',
                  lineHeight: 1,
                }}
                key={index}
              >
                {condition}
              </Box>
            ))}
          </Box>
        </Box>
      );
    },
    [rows]
  );

  useEffect(() => {
    if (symbol) {
      let { from: _from } = analysisCriteria;
      const from = getNextTimestamp(_from, 0, TIME.MINUTES);
      const to = getNextTimestamp(_from, 1, TIME.MINUTES);
      if (from && to) {
        getTrades(symbol, from, to).then((data) => {
          const _data = data
            .map((d) => {
              return {
                time: timeHHMMSS(d.Timestamp),
                price: d.Price,
                size: d.Size,
                conditions: d.Conditions.join(','),
              };
            })
            .sort((b, a) => a.price - b.price);

          //group by conditions
          const groupedData = _data.reduce((acc, curr) => {
            if (acc[curr.conditions]) {
              acc[curr.conditions].push(curr);
            } else {
              acc[curr.conditions] = [curr];
            }
            return acc;
          }, {});

          // consolidate groupedData by price inside each conditional grouped data
          const consolidatedData = Object.keys(groupedData).map((key) => {
            const data = groupedData[key];
            const consolidated = data.reduce((acc, curr) => {
              if (acc[curr.price]) {
                acc[curr.price] += curr.size;
              } else {
                acc[curr.price] = curr.size;
              }
              return acc;
            }, {});

            return {
              conditions: key,
              data: Object.keys(consolidated).map((price) => ({
                price,
                size: consolidated[price],
              })),
            };
          });

          // create a flat final data array with conditions, price, size
          const finalData = consolidatedData.reduce((acc, curr) => {
            return [
              ...acc,
              ...curr.data.map((d) => ({
                conditions: curr.conditions.split(','),
                price: d.price,
                size: d.size,
              })),
            ];
          }, []);

          setRows(finalData);
        });
      }
    }
  }, [JSON.stringify(analysisCriteria), symbol]);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            className="List"
            height={height}
            itemCount={rows.length}
            itemSize={35}
            width={width}
          >
            {VirtualizedRow}
          </List>
        )}
      </AutoSizer>
    </Box>
  );
};

export default TradesAnalyzer;
