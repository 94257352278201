import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButton as MuiToggleButton,
} from '@mui/material';
import { noop } from 'lodash';
import styles from './ToggleButtonGroup.module.css';

const ToggleButtonGroup = ({
  buttons = [],
  onChange = noop,
  selected = [],
  exclusive = true,
  classes = {},
  verticalAlign = false,
}) => {
  return (
    <MuiToggleButtonGroup
      value={selected}
      onChange={onChange}
      aria-label="Category"
      exclusive={exclusive}
      sx={{ flexDirection: verticalAlign ? 'column' : 'row' }}
      classes={{ root: classes.root || styles.buttonGroup }}
    >
      {buttons.map(({ name, value }) => (
        <MuiToggleButton
          value={value}
          aria-label={name}
          classes={{
            selected: styles.toggleButtonSelected,
            root: styles.toggleButtonRoot,
          }}
        >
          {name}
        </MuiToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
