export const fetcher = (...args) => {
  return fetch(...args, { credentials: 'include' }).then(async (res) => {
    let payload;
    try {
      console.log('Status: ', res.status);
      if (res.status === 204) return null; // 204 does not have body
      payload = await res.json();
    } catch (e) {
      console.log('Err: ', args, e);
      return Promise.reject(e || new Error('Something went wrong'));
    }
    if (res.ok) {
      return payload;
    } else {
      return Promise.reject({
        status: res.status,
        error: payload?.error || new Error('Something went wrong'),
      });
    }
  });
};
