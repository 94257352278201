import '@/assets/base.scss';
import '@/assets/modern.css';
import 'node_modules/react-grid-layout/css/styles.css';
import 'node_modules/react-resizable/css/styles.css';

import { Layout } from '@/components/Layout';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'react-hot-toast';
import { CacheProvider } from '@emotion/react';

import { lightTheme, darkTheme } from '@/assets/theme';
import createEmotionCache from '@/assets/createEmotionCache';

// import { connectSocket } from '@/lib/socket';

import { wrapper } from 'store/store';
import { useEffect } from 'react';
import { selectTheme, setDatapoints, setWatchlists } from 'store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setConnectionStatus, updateStream } from 'store/streamSlice';
import { geSSEStream } from '@/lib/stream';
import { connectedSocket } from '@/lib/socket';
import { MESSAGE_TYPES, SUBSCRIPTION_TYPES } from '@/lib/utils/constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getWatchlists } from '@/lib/watchlists';
import { getDatapoints } from '@/lib/datapoints';
import { SessionProvider } from 'next-auth/react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const dispatch = useDispatch();
  const appTheme = useSelector(selectTheme);

  const theme = appTheme === 'dark' ? darkTheme : lightTheme;

  // useEffect(() => {
  //   if (session) {
  //     if (!session.user) {
  //       dispatch(setUser({ isLoading: false, isValid: false }));
  //       router.replace('/login');
  //       return;
  //     }

  //     // if (session.user) {
  //     //   // dispatch(setUser({ ...data.user, isLoading: false, isValid: true }));
  //     // }
  //   } else if (
  //     !(router.pathname === '/login' || router.pathname === '/sign-up')
  //   ) {
  //     // dispatch(setUser({ isLoading: false, isValid: false }));
  //     router.replace('/login');
  //   }
  // }, [router, session, dispatch]);

  // useEffect(() => {
  //   const establishStreamConnection = async () => {

  //     const stream = await geSSEStream();

  //     stream.onmessage = (e) => {
  //       const streamData = JSON.parse(e.data || '[]');

  //       if (streamData.type === 'CONNECTION') {
  //         if (streamData.connected) _res();
  //         else _rej();
  //       } else {
  //         dispatch(updateStream(streamData));
  //       }
  //     };
  //   };

  //   establishStreamConnection();
  // }, []);

  const fetchDatapoints = async () => {
    const _datapoints = await getDatapoints();
    dispatch(setDatapoints(_datapoints));
  };
  //Fetch Watchlist
  const fetchWatchlists = async () => {
    const { watchlists: _watchlists } = await getWatchlists();
    dispatch(setWatchlists(_watchlists));
  };

  useEffect(() => {
    const onMount = async () => {
      const socket = await connectedSocket();
      let _res;
      let _rej;

      socket.on(MESSAGE_TYPES.TRADE, (trade) => {
        dispatch(
          updateStream({
            type: MESSAGE_TYPES.TRADE,
            trade,
          })
        );
      });

      socket.on(MESSAGE_TYPES.BULK_TRADES, (trades) => {
        dispatch(
          updateStream({
            type: MESSAGE_TYPES.BULK_TRADES,
            trades,
          })
        );
      });

      socket.on(MESSAGE_TYPES.BULK_QUOTES, (quotes) => {
        dispatch(
          updateStream({
            type: MESSAGE_TYPES.BULK_QUOTES,
            quotes,
          })
        );
      });

      socket.on(MESSAGE_TYPES.MOMENTUM, (stocks) => {
        dispatch(
          updateStream({
            type: MESSAGE_TYPES.MOMENTUM,
            stocks,
          })
        );
      });

      // Initialize connection status
      dispatch(
        setConnectionStatus(
          new Promise((res, rej) => {
            _res = res;
            _rej = rej;
          })
        )
      );

      socket.on(MESSAGE_TYPES.DATACLIENT_WS_CONNECTED, (status) => {
        if (status) {
          _res();
        }
      });

      //Fetch watchlist
      fetchWatchlists();

      fetchDatapoints();
    };

    onMount();
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SessionProvider>
          <ThemeProvider theme={theme}>
            <Layout>
              <CssBaseline />
              <Component {...pageProps} />
              <Toaster
                position="bottom-left"
                toastOptions={{
                  style: {
                    borderRadius: 0,
                  },
                }}
              />
            </Layout>
          </ThemeProvider>
        </SessionProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default wrapper.withRedux(MyApp);
