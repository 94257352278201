import { dateMMDDYYY } from '@/lib/utils';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';

const MinimizedSymbolSummary = ({ symbol, timestamp, gapUp }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ color: theme.palette.primary.dark, width: 60 }}>
          {symbol}
        </Box>
        {/* <Box sx={{ color: theme.palette.grey[700] }}>
          {dateMMDDYYY(timestamp)}
        </Box> */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ color: theme.palette.grey[700], marginLeft: 2 }}>
            {dateMMDDYYY(timestamp)}
          </Box>
          <Box
            sx={{
              color: theme.palette.success.dark,
              // fontSize: 20,
              ml: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {gapUp}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MinimizedSymbolSummary;
