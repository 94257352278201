import React, { useState, useEffect } from 'react';
import styles from './Clock.module.css';
import Card from '@/components/Card';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const DigitalClock = () => {
  const [time, setTime] = useState(new Date());
  const hours = time.getHours() % 12 || 12;
  const minutes = time.getMinutes().toString().padStart(2, '0');
  const seconds = time.getSeconds().toString().padStart(2, '0');
  const ampm = time.getHours() >= 12 ? 'PM' : 'AM';

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box className={styles.time}>
        {`${hours}:${minutes}:${seconds}`}{' '}
        <Typography variant="h4">{ampm}</Typography>
      </Box>
    </Card>
  );
};

export default DigitalClock;
