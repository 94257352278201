import { fetcher } from '../fetch';
import { SUBSCRIPTION_TYPES } from '../utils/constants';

export const geSSEStream = async () => {
  return new EventSource(`/api/real-time/stream`);
};

export const subscribeForTrades = async (payload) => {
  return await fetcher('/api/real-time/stream/subscribe', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: SUBSCRIPTION_TYPES.TRADES, symbols: payload }),
  });
};

export const unsubscribeFromTrades = async (payload) => {
  return await fetcher('/api/real-time/stream/unsubscribe', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: SUBSCRIPTION_TYPES.TRADES, symbols: payload }),
  });
};

export const subscribeForQuotes = async (payload) => {
  return await fetcher('/api/real-time/stream/subscribe', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: SUBSCRIPTION_TYPES.QUOTES, symbols: payload }),
  });
};

export const unsubscribeFromQuotes = async (payload) => {
  return await fetcher('/api/real-time/stream/unsubscribe', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: SUBSCRIPTION_TYPES.QUOTES, symbols: payload }),
  });
};
