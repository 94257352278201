import React, { useState, useEffect, useRef } from 'react';
import { EnhancedTable } from '@/components/Table';
import { stockFieldsConfig } from 'config/fields';
import { updateWatchlist } from '@/lib/watchlists';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStreamConnectionStatus,
  selectStreamTrades,
} from 'store/streamSlice';
import { subscribeForTrades, unsubscribeFromTrades } from '@/lib/stream';
import { ACTIONS, CELL_TYPES } from '@/lib/utils/constants';
import _, { noop } from 'lodash';
import { selectWatchlists, setWatchlists } from 'store/appSlice';
import { Button } from '@/components/Button';
import { File } from 'react-feather';
import { IconButton } from '@mui/material';
import { Plagiarism } from '@mui/icons-material';

const Watchlist = ({ onRowClick, openFilingsSummary = noop }) => {
  const dispatch = useDispatch();
  const [watchlistConfig, setWatchlistConfig] = useState({
    data: [],
  });
  const [symbolToBeAdded, setSymbolToBeAdded] = useState('');
  const streamConnected = useSelector(selectStreamConnectionStatus);
  const trades = useSelector(selectStreamTrades);
  const watchlists = useSelector(selectWatchlists);

  const _onChange = (dataIndex, value) => {
    setWatchlistConfig((_config) => {
      const _data = [..._config.data];
      _data[dataIndex].Symbol = value?.toUpperCase();
      _config.data = _data;
      return { ..._config };
    });
  };

  const _updateWatchlist = async (_data = []) => {
    const [watchlist] = _.cloneDeep(watchlists);

    try {
      await updateWatchlist(watchlist._id, {
        ...watchlist,
        symbols: _data.filter((symbol) => !!symbol),
      });

      watchlist.symbols = _data.filter((symbol) => !!symbol);

      dispatch(setWatchlists([watchlist, ...(watchlists.slice(1) || [])]));
    } catch (err) {
      toast.error(err.message);
    }
  };

  const _onSubmit = (dataIndex, value) => {
    if (event.keyCode == 13) {
      const [watchlist] = _.cloneDeep(watchlists);
      if (!watchlist.symbols.find((symbol) => !symbol)) {
        if (dataIndex === 0) {
          watchlist.symbols.unshift(value);
        } else {
          watchlist.symbols[dataIndex - 1] = value;
        }
      }

      setWatchlistConfig((_config) => {
        let _data = [..._config.data].map((_d) => ({
          ..._d,
          CurrentPrice: 0,
          inEditMode: !_d.Symbol,
        }));

        if (!_data.find((_d) => !_d.Symbol)) {
          _data.unshift({
            Symbol: '',
            onChange: (dataIndex, value) => _onChange(dataIndex, value),
            onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
            inEditMode: true,
            onClick: noop,
          });
        }

        _config.data = _data;

        return { ..._config };
      });

      _updateWatchlist(watchlist.symbols);

      // dispatch(setWatchlists([watchlist, (watchlists.slice(1) || [])]));
    }
  };

  const _onClick = (dataIndex) => {
    setWatchlistConfig((_config) => {
      _config.data[dataIndex].inEditMode = true;
      return _config;
    });
  };

  const updateStreamSubscriptions = async (watchlists) => {
    const [watchlist] = _.cloneDeep(watchlists);

    const symbols = watchlist.symbols;
    // await streamConnected;
    symbols?.length && subscribeForTrades(symbols);
  };

  const handleDelete = (evt, row, index) => {
    const [watchlist] = _.cloneDeep(watchlists);
    watchlist.symbols = watchlist.symbols.filter(
      (val, ind) => ind !== index - 1
    );
    _updateWatchlist(watchlist.symbols);
    row.Symbol && unsubscribeFromTrades([row.Symbol]);
    dispatch(setWatchlists([watchlist, ...(watchlists.slice(1) || [])]));
    evt.stopPropagation();
  };

  const handleAdd = (event) => {
    if (event.keyCode == 13) {
      setWatchlistConfig((_config) => {
        const currentList = _config.data;
        currentList.push({
          Symbol: symbolToBeAdded,
          CurrentPrice: 0,
          onChange: (dataIndex, value) => _onChange(dataIndex, value),
          onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
          inEditMode: false,
          onClick: (dataIndex, value) => _onClick(dataIndex, value),
        });

        _config.data = currentList;
        _updateWatchlist(currentList);
        subscribeForTrades([symbolToBeAdded]);

        return _config;
      });
    }
  };

  const _onFilingSummaryClick = (evt, row) => {
    openFilingsSummary(row.Symbol);
    evt.stopPropagation();
  };

  useEffect(() => {
    setWatchlistConfig({
      data: [
        {
          Symbol: '',
          CurrentPrice: 0,
          onChange: (dataIndex, value) => _onChange(dataIndex, value),
          onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
          inEditMode: true,
          onClick: noop,
        },
        ...(watchlists[0]?.symbols || []).map((symbol) => ({
          Symbol: symbol,
          CurrentPrice: 0,
          onChange: (dataIndex, value) => _onChange(dataIndex, value),
          onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
          inEditMode: false,
          onClick: (dataIndex, value) => _onClick(dataIndex, value),
        })),
      ],
      columns: [
        stockFieldsConfig.actions.delete,
        stockFieldsConfig.enrichedEditableSymbolWithMovement,
        stockFieldsConfig.Percentage,
        stockFieldsConfig.CurrentPrice,
        stockFieldsConfig.Volume,
        {
          name: 'filing-summary',
          disablePadding: true,
          align: 'right',
          formatter: () => {
            return (
              <IconButton
                color="primary"
                aria-label="Filing summary"
                size="small"
                onClick={noop}
                title="Filing summary"
              >
                <Plagiarism />
              </IconButton>
            );
          },
          type: CELL_TYPES.ACTION,
          action: _onFilingSummaryClick,
        },
      ],
    });
    // updateStreamSubscriptions(watchlists);
  }, [JSON.stringify(watchlists)]);

  useEffect(() => {
    if (Object.keys(trades).length) {
      setWatchlistConfig((_config) => {
        let _data = [...(_config.data || [])].map((_d) => ({
          ..._d,
          CurrentPrice: trades[_d.Symbol],
        }));

        _config.data = _data;
        return { ..._config };
      });
    }
  }, [trades]);

  useEffect(() => {
    const { data = [] } = watchlistConfig;
    const symbols = data.map(({ Symbol }) => Symbol).filter((val) => !!val);
    symbols.length && subscribeForTrades(symbols);
  }, [watchlistConfig.data.map(({ Symbol }) => Symbol).join(',')]);

  return (
    // <Box>
    //   <TextField
    //     placeholder="Symbol"
    //     size="small"
    //     onChange={(event) =>
    //       setSymbolToBeAdded(event.target.value.toUpperCase())
    //     }
    //     onKeyDown={(event) => handleAdd(event)}
    //     value={symbolToBeAdded}
    //     sx={{ width: 100 }}
    //     onBlur={() =>
    //       handleAdd({
    //         keyCode: 13,
    //       })
    //     }
    //   />
    <EnhancedTable
      config={watchlistConfig}
      onRowClick={onRowClick}
      heading={'Watchlist'}
      actions={{
        [ACTIONS.DELETE]: handleDelete,
      }}
    />
    // </Box>
  );
};

export default Watchlist;
