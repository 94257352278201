import { MENU_CATEGORIES, MENU_TYPES } from '@/lib/utils/constants';
import { Codesandbox, Cpu, File, Home, Sliders, Search } from 'react-feather';

export const navMenu = (customPages = []) => [
  {
    type: MENU_TYPES.MENU_LIST,
    items: [
      {
        label: 'Home',
        Icon: Home,
        link: '/',
        description:
          'Access recent activity, insights, and quick links to core tools',
      },
      // {
      //   label: 'Backtest',
      //   Icon: Codesandbox,
      //   link: '/backtest',
      //   description:
      //     'Simulate strategies on historical data to evaluate performance',
      // },
      // {
      //   label: 'Analyze',
      //   Icon: Cpu,
      //   link: '/analyze',
      //   description:
      //     'Explore market trends and refine strategies with advanced analytics',
      // },
      {
        label: 'Datapoint Builder',
        Icon: Sliders,
        link: '/builder/datapoints',
        description: 'Generate custom data points for deeper market insights',
        hidden: false,
      },
      {
        label: 'Scanner Builders',
        Icon: Search,
        link: '/builder/scanner',
        description: 'Build custom scanners to identify trading opportunities',
        hidden: false,
      },
      // {
      //   label: 'Strategy Builder',
      //   Icon: Sliders,
      //   link: '/builder/strategies',
      //   description: 'Create and customize trading strategies with ease',
      //   hidden: false,
      // },
      // {
      //   label: 'Dashboards and Reports',
      //   Icon: Sliders,
      //   link: '/dashboards',
      //   description:
      //     'Visualize metrics and generate reports to track performance',
      //   hidden: false,
      // },
      // {
      //   label: 'Scanners',
      //   Icon: Sliders,
      //   link: '/scanners',
      //   description: 'Run pre-built or custom scanners to monitor the market',
      //   hidden: false,
      // },
    ],
  },
  {
    type: MENU_TYPES.DIVIDER,
  },
  {
    category: MENU_CATEGORIES.CUSTOM_PAGES,
    type: MENU_TYPES.MENU_LIST,
    items: [
      ...customPages.map(({ title, _id }) => ({
        label: title,
        Icon: File,
        link: `/custom/${_id}`,
      })),
    ],
  },
];
