import React, { useState, useRef, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import enUS from 'date-fns/locale/en-US';

import {
  AlignHorizontalRight,
  CalendarMonth,
  ChangeHistory,
  Refresh,
} from '@mui/icons-material';
import { Box, Card, FormControlLabel, IconButton, styled } from '@mui/material';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { CONFIG_TYPES, TIMEFRAME_OPTIONS } from '@/lib/utils/constants';
import styles from './MoreMenu.module.css';
import { DateRange } from 'react-date-range';
import moment from 'moment-timezone';
import ToggleButtonGroup from '../ToggleButtonGroup';

export default function MoreMenu({
  updateOptions,
  updateChartConfig,
  refreshData,
  chartConfig,
}) {
  const [open, setOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState(['1Min']);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const anchorRef = useRef(null);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleOptionsChange = (event, newSelections) => {
    setSelectedOptions(newSelections);
    updateOptions({
      volumeProfile: newSelections.includes('volume-profile'),
      deltaTooltip: newSelections.includes('delta-tooltip'),
    });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const onAcceptedDate = (date) => {
    const [{ startDate = new Date(), endDate = new Date() }] = date;
    updateChartConfig({
      type: CONFIG_TYPES.SELECTED_DAY_RANGE,
      value: {
        startDate,
        endDate,
      },
    });
    toggleCalendar();
  };

  const handleTimeframeChange = (evt, value) => {
    setSelectedTimeframe(value);
    updateChartConfig({
      type: CONFIG_TYPES.TIMEFRAME,
      value,
    });
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const { timeframe } = chartConfig;
    setSelectedTimeframe(timeframe);
  }, [JSON.stringify(chartConfig)]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 1,
      }}
    >
      <Box sx={{ display: 'flex', marginLeft: 1 }}>
        <IconButton
          color="primary"
          aria-label="get previous day data"
          onClick={() => updateChartConfig({ type: CONFIG_TYPES.PREVIOUS_DAY })}
        >
          <NavigateBefore />
        </IconButton>

        <IconButton
          color="primary"
          aria-label="get next day data"
          onClick={() => updateChartConfig({ type: CONFIG_TYPES.NEXT_DAY })}
        >
          <NavigateNext />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="Refresh data"
          onClick={() => refreshData()}
        >
          <Refresh />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            marginLeft: '1rem',
            position: 'relative',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box
            onClick={toggleCalendar}
            sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="primary" aria-label="Get data for daily chart">
              <CalendarMonth />
            </IconButton>
            <Box sx={{ display: 'flex', gap: '0.25rem' }}>
              <span>{moment(chartConfig.from).format('MM-DD-YYYY')}</span>
              <span>-</span>
              <span>{moment(chartConfig.to).format('MM-DD-YYYY')}</span>
            </Box>
          </Box>
          <Box>
            <ToggleButtonGroup
              exclusive
              selected={selectedTimeframe}
              onChange={handleTimeframeChange}
              classes={{ root: styles.buttonGroup }}
              buttons={TIMEFRAME_OPTIONS}
            ></ToggleButtonGroup>
          </Box>
          <Box sx={{ position: 'absolute', top: '2rem', left: 0, zIndex: 999 }}>
            {showCalendar && (
              <ClickAwayListener
                onClickAway={() => onAcceptedDate(selectedDateRange)}
              >
                <Card>
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setSelectedDateRange([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={selectedDateRange}
                    locale={enUS}
                    maxDate={new Date()}
                  />
                </Card>
              </ClickAwayListener>
            )}
          </Box>
        </Box>
      </Box>
      <ToggleButtonGroup
        selected={selectedOptions}
        onChange={handleOptionsChange}
        exclusive={false}
        aria-label="Chart options"
        size="small"
        color="primary"
        classes={{ root: styles.buttonGroup }}
        buttons={[
          {
            name: <ChangeHistory />,
            value: 'delta-tooltip',
          },
          {
            name: <AlignHorizontalRight />,
            value: 'volume-profile',
          },
        ]}
      ></ToggleButtonGroup>
      {/* <MuiToggleButtonGroup
        value={selectedOptions}
        onChange={handleOptionsChange}
        aria-label="Chart options"
        size="small"
        color="primary"
        classes={{ root: styles.buttonGroup }}
      >
        <MuiToggleButton
          value="delta-tooltip"
          aria-label="Delta tooltip"
          classes={{
            selected: styles.toggleButtonSelected,
            root: styles.toggleButtonRoot,
          }}
        >
          <ChangeHistory />
        </MuiToggleButton>
        <MuiToggleButton
          value="volume-profile"
          aria-label="Volume profile"
          classes={{
            selected: styles.toggleButtonSelected,
            root: styles.toggleButtonRoot,
          }}
        >
          <AlignHorizontalRight />
        </MuiToggleButton>
      </MuiToggleButtonGroup> */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleClose}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
