import { fetcher } from '../fetch';

//Based on code below create methods for chart metadata
export const createChartMetadata = async (symbol, price, color, title) => {
  return await fetcher('/api/user/metadata/chart', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ symbol, price, color, title }),
  });
};

export const getAllChartMetadata = async () => {
  return await fetcher(`/api/user/metadata/chart`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getChartMetadataBySymbol = async (symbol) => {
  return await fetcher(`/api/user/metadata/chart?symbol=${symbol}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const deleteChartMetadata = async (id) => {
  return await fetcher(`/api/user/metadata/chart/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
};
