import ChartWrapper from '@/components/Chart/ChartWrapper';
import { WIDGET_TYPES } from '@/lib/utils/constants';
import {
  BarChart,
  CandlestickChart,
  ListAlt,
  ManageSearch,
  WaterfallChart,
  AccessTime,
} from '@mui/icons-material';
import StockEntry from 'widgets/StockEntry';
import DigitalClock from 'widgets/Clock';
import Fundamentals from 'widgets/Fundamentals';
import History from 'widgets/History';
import RealTimeGainersAndLosers from 'widgets/RealTimeGainersAndLosers';
import RealTimeHalts from 'widgets/RealTimeHalts';
import RealTimeTrades from 'widgets/RealTimeTrades';
import ScannerResults from 'widgets/ScannerResults';

export const widgets = [
  {
    type: WIDGET_TYPES.GAINERS,
    name: 'Gainers',
    Icon: BarChart,
  },
  {
    type: WIDGET_TYPES.HALTS,
    name: 'Halts',
    Icon: WaterfallChart,
  },
  {
    type: WIDGET_TYPES.CHART,
    name: 'Chart',
    Icon: CandlestickChart,
  },
  {
    type: WIDGET_TYPES.STOCK_SEARCH,
    name: 'Stock search',
    Icon: ManageSearch,
  },
  {
    type: WIDGET_TYPES.FUNDAMENTALS,
    name: 'Fundamentals',
    Icon: ListAlt,
  },
  {
    type: WIDGET_TYPES.HISTORY,
    name: 'History',
    Icon: ListAlt,
  },
  {
    type: WIDGET_TYPES.REALTIME_TRADES,
    name: 'Real time trades',
    Icon: ListAlt,
  },
  {
    type: WIDGET_TYPES.DIGITAL_CLOCK,
    name: 'Clock',
    Icon: AccessTime,
  },
  // {
  //   type: WIDGET_TYPES.WATCHLIST,
  //   name: 'Watchlist',
  //   Icon: Preview,
  // },
];

export const WidgetIdToComponent = {
  [WIDGET_TYPES.GAINERS]: {
    Component: RealTimeGainersAndLosers,
    height: 30,
    width: 6,
    defaultProps: ['onRowClick'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.HALTS]: {
    Component: RealTimeHalts,
    height: 30,
    width: 6,
    defaultProps: ['onRowClick'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.STOCK_SEARCH]: {
    Component: StockEntry,
    height: 5,
    width: 4,
    defaultProps: ['onStockEntryClick'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.CHART]: {
    Component: ChartWrapper,
    height: 30,
    width: 15,
    defaultProps: ['config'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.FUNDAMENTALS]: {
    Component: Fundamentals,
    height: 8,
    width: 12,
    defaultProps: ['fundamentals'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.HISTORY]: {
    Component: History,
    height: 30,
    width: 12,
    defaultProps: ['symbol', 'onRowClick'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.WATCHLIST]: {
    Component: RealTimeGainersAndLosers,
    height: 4,
    width: 4,
    defaultProps: ['onRowClick'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.SCANNER_RESULTS]: {
    Component: ScannerResults,
    height: 30,
    width: 6,
    defaultProps: ['onRowClick'],
    id: (type = '', props = {}) => `${props.scannerId}`,
  },
  [WIDGET_TYPES.REALTIME_TRADES]: {
    Component: RealTimeTrades,
    height: 80,
    width: 6,
    defaultProps: ['symbol'],
    id: (type = '') => type,
  },
  [WIDGET_TYPES.DIGITAL_CLOCK]: {
    Component: DigitalClock,
    height: 9,
    width: 4,
    defaultProps: [],
    id: (type = '') => type,
  },
};
