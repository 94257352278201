import { EnhancedTable } from '@/components/Table';
import { getScannerById } from '@/lib/scanners';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectScannerResults } from 'store/streamSlice';

const ScannerResults = ({ onRowClick, scannerId = 'none' }) => {
  const [scanner, setScanner] = useState({});
  const [config, setConfig] = useState({
    data: [],
    columns: [
      {
        label: 'Symbol',
        numeric: false,
        name: 'symbol',
        disablePadding: false,
        align: 'left',
      },
    ],
  });
  const scannerResults = useSelector(selectScannerResults);

  const fetchScanner = async () => {
    try {
      const { scanner } = await getScannerById(scannerId);
      setScanner(scanner);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const filtered = scannerResults.filter(
      (result) => scannerId === result.scannerId
    );

    setConfig((_config) => {
      const _data = [..._config.data, ...filtered];
      _config.data = _data;
      return _config;
    });
  }, JSON.stringify(scannerResults));

  useEffect(() => {
    fetchScanner();
  }, []);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <EnhancedTable config={config} heading={scanner.name} />
    </Box>
  );
};

export default ScannerResults;
