import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Divider,
  InputAdornment,
} from '@mui/material';
import { Modal } from '@/components/Modal/Modal';
import _, { noop } from 'lodash';
import { fetchStockSuggestions } from '@/lib/stock';
import KeyValueCell from '@/components/KeyValueCell';
import styles from './StockEntry.module.css';
import AddToWatchlist from '@/components/AddToWatchlist';
import { Button } from '@/components/Button';
import { AddToQueue, Search } from '@mui/icons-material';

const StockList = ({ stocks, search, onClick }) => {
  const highlightSymbol = (symbol) => {
    if (!search) return symbol;

    const regex = new RegExp(`(${search})`, 'i');
    return symbol.replace(
      regex,
      (match) =>
        `<span style="font-weight: 600; text-decoration: underline;">${match}</span>`
    );
  };

  return (
    <Box
      sx={{
        overflowY: 'auto',
        flex: 1,
      }}
    >
      {stocks.map((stock) => (
        <Box
          className={styles.suggestionContainer}
          key={stock._id}
          onClick={() => onClick(stock.symbol)}
        >
          <Box
            sx={{
              width: '3rem',
              height: '3rem',
              backgroundColor: 'var(--active-bg)',
            }}
          >
            <AddToWatchlist symbol={stock.symbol}>
              <Button classes={{ root: styles.watchlistButtonRoot }}>
                <AddToQueue />
              </Button>
            </AddToWatchlist>
          </Box>

          <Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'end' }}>
              <Box
                sx={{
                  color: 'primary.main',
                  fontSize: '1.2rem',
                  fontWeight: 300,
                }}
                dangerouslySetInnerHTML={{
                  __html: highlightSymbol(stock.symbol),
                }}
              />

              <Typography
                sx={{ color: 'var(--font-color)' }}
                variant="subtitle2"
              >
                {stock.country}
              </Typography>
              <Divider orientation="vertical" variant="middle" flexItem />

              <Typography
                sx={{ color: 'var(--font-color)' }}
                variant="subtitle2"
              >
                {stock.sector}
              </Typography>
            </Box>
            <Typography variant="body1">{stock.name}</Typography>
          </Box>
          <Box>
            <KeyValueCell
              label={'Market Cap'}
              value={`$${Number(stock.marketCap).toLocaleString()}`}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const SearchModal = ({ show, onClose, onSuggestionClick = noop }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (inputValue) {
      const filtered = suggestions.filter(({ symbol }) =>
        symbol.toLowerCase().startsWith(inputValue.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  }, [inputValue, suggestions]);

  const throttledSearchSuggestions = useCallback(
    _.throttle(async (searchString) => {
      const { suggestions = [] } = await fetchStockSuggestions(searchString);
      setSuggestions(suggestions);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const searchString = e?.target?.value?.toUpperCase() || '';
    setInputValue(searchString);
    throttledSearchSuggestions(searchString);
  };

  const _onSuggestionClick = (symbol) => {
    onSuggestionClick(symbol);
    onClose();
  };

  return (
    <Modal open={show} onClose={onClose} aria-labelledby="stock-symbol-modal">
      <Box
        sx={{
          width: '80%',
          maxWidth: 1000,
          bgcolor: 'var(--card-bg)',
          boxShadow: 24,
          p: 4,
          mx: 'auto',
          my: '5vh',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          label="Stock Symbol"
          variant="outlined"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          autoFocus
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            },
          }}
        />
        {filteredSuggestions.length > 0 && (
          <StockList
            stocks={filteredSuggestions}
            search={inputValue}
            onClick={_onSuggestionClick}
          />
        )}
      </Box>
    </Modal>
  );
};

export default SearchModal;
