import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { isFunction } from '@/lib/utils';
import styles from './EnhancedTable.module.css';
import { useTheme } from '@mui/material/styles';
import { ACTIONS, CELL_TYPES } from '@/lib/utils/constants';
import { noop } from 'lodash';
import Card from '../Card';
import { MenuItem } from '@mui/material';
import { Menu } from '@/components/Menu';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={styles.tableHeader}>
        {headCells.map((headCell, idx) => (
          <TableCell
            key={headCell.name + idx}
            align={headCell.numeric ? 'right' : headCell.align || 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.name ? order : false}
            className={styles.tableHeaderCell}
            sx={{
              paddingRight: '0.5rem',
              paddingLeft: '0.5rem',
              fontSize: '0.8rem',
              fontWeight: 400,
              lineHeight: '1.5rem',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.name}
              direction={orderBy === headCell.name ? order : 'asc'}
              onClick={createSortHandler(headCell.name)}
              classes={{ icon: styles.icon }}
            >
              {headCell.label}
              {orderBy === headCell.name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const EnhancedTable = ({
  config,
  onRowClick = () => {},
  highlightItems = false,
  heading,
  actions = {
    [ACTIONS.DELETE]: noop,
  },
  defaultRowsPerPage = 25,
  menuItems = [],
  onMenuItemClick = noop,
}) => {
  const theme = useTheme();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selectedRow, setSelectedRow] = React.useState('');
  const [selectedRowForContextMenu, setSelectedRowForContextMenu] =
    React.useState(null);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [menuPosition, setMenuPosition] = React.useState(null);
  const rows = config?.data || [];
  const headCells = config?.columns || [];
  const rowBackgroundColor = config?.rowBackgroundColor || noop;

  const handleRightClick = (event, row) => {
    event.preventDefault();
    setSelectedRowForContextMenu(row);
    setMenuPosition({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  };

  const handleCloseMenu = () => {
    setMenuPosition(null);
    setSelectedRowForContextMenu(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const _onRowClick = (rowData, idx) => {
    setSelectedRow(`${rowData.symbol}-${idx}`);
    onRowClick(rowData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box
      sx={{ height: '100%', flex: 1, display: 'flex', flexDirection: 'column' }}
    >
      {heading && (
        <Typography variant="body1" classes={{ root: styles.heading }}>
          {heading}
        </Typography>
      )}

      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 0.25,
          flex: 1,
          position: 'relative',
        }}
      >
        <TableContainer sx={{ flex: 1 }}>
          <Table
            sx={{ overflow: 'auto' }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                minHeight: 200,
                maxHeight: 750,
                overflow: 'auto',
                flex: 1,
                position: 'relative',
              }}
            >
              {visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const backgroundColor = rowBackgroundColor
                    ? typeof rowBackgroundColor === 'function'
                      ? rowBackgroundColor(row)
                      : rowBackgroundColor
                    : 'none';

                  return (
                    <TableRow
                      hover
                      onClick={() => _onRowClick(row, index)}
                      onContextMenu={(event) => handleRightClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{
                        cursor: 'pointer',
                        borderLeft:
                          selectedRow === `${row.symbol}-${index}`
                            ? `3px solid ${theme.palette.primary.dark}`
                            : 'none',
                      }}
                      classes={{ root: styles[`bg-${backgroundColor}`] }}
                      key={labelId}
                    >
                      {headCells.map((cell) => {
                        let val = row[cell.name];
                        const isAnAction = cell.type === CELL_TYPES.ACTION;
                        if (isFunction(cell.formatter)) {
                          val = cell.formatter(
                            row[cell.name],
                            cell.name,
                            row,
                            index
                          );
                        }
                        const action =
                          actions[cell.action] || cell.action || noop;
                        const inEditMode = row.inEditMode;
                        const textTransform =
                          cell.textTransform ?? 'capitalize';

                        return (
                          <TableCell
                            key={cell.name}
                            align={
                              cell.numeric ? 'right' : cell.align || 'left'
                            }
                            sx={{
                              paddingRight: '0.5rem',
                              paddingLeft: '0.5rem',
                              textTransform,
                            }}
                          >
                            {isAnAction ? (
                              !inEditMode ? (
                                <div onClick={(evt) => action(evt, row, index)}>
                                  {val}
                                </div>
                              ) : (
                                <></>
                              )
                            ) : (
                              val || '-'
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <Box
                  sx={{
                    height: 300,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                  }}
                >
                  <Box>No Data</Box>
                </Box>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      {/* Context Menu */}
      {menuItems.length ? (
        <Menu
          container={document.getElementById('application')}
          open={!!menuPosition}
          onClose={handleCloseMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            menuPosition
              ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
              : undefined
          }
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                onMenuItemClick(item, selectedRowForContextMenu);
                handleCloseMenu();
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <></>
      )}
    </Box>
  );
};
