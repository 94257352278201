import React, { useEffect, useRef } from 'react';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { cssVar, getNextTimestamp } from '@/lib/utils';
import { useSelector } from 'react-redux';
import Card from '@/components/Card';
import { selectTheme } from 'store/appSlice';
import _ from 'lodash';
import { selectSelectedCriteriaForAnalysis } from 'store/analysisSlice';
import { getBidAsk } from '@/lib/stock';
import { TIME } from '@/lib/utils/constants';

// Register necessary components for the bar chart
Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

const BidAskScatterLine = ({ chartConfig }) => {
  const { symbol } = chartConfig;
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const analysisCriteria = useSelector(selectSelectedCriteriaForAnalysis);
  const appTheme = useSelector(selectTheme);
  const UP_COLOR = cssVar('--price-move-up-text');
  const DOWN_COLOR = cssVar('--price-move-down-text');
  const ticksColor = cssVar('--text-primary');

  // Update the chart theme
  useEffect(() => {
    const chart = chartInstance.current;
    // Check if the chart instance is available
    if (!chart) {
      return;
    }

    // Update the chart options
    const chartOptions = _.cloneDeep(chart.options);

    chart.options = {
      ...chartOptions, // Merge existing options
      // Apply new options
      scales: {
        x: {
          title: {
            color: ticksColor,
          },
          ticks: {
            color: ticksColor, // Change the y-axis label color
          },
        },
        y: {
          title: {
            color: ticksColor,
          },
          ticks: {
            color: ticksColor, // Change the y-axis label color
          },
        },
      },
    };

    // Apply the changes to the chart
    chart.update('none');
  }, [appTheme]);

  // Simulate real-time updates
  useEffect(() => {
    if (symbol) {
      let { from: _from } = analysisCriteria;
      const from = getNextTimestamp(_from, -5, TIME.MINUTES);
      const to = getNextTimestamp(_from, 1, TIME.MINUTES);
      if (from && to) {
        getBidAsk(symbol, from, to).then((data) => {
          const bids = [];
          const asks = [];

          data.forEach((d) => {
            const timestamp = new Date(d.Timestamp).getTime();

            bids.push({
              x: timestamp,
              y: d.BidPrice,
            });

            asks.push({
              x: timestamp,
              y: d.AskPrice,
            });
          });

          const chart = chartInstance.current;
          if (chart) {
            const data = chart.data;
            data.datasets = [
              {
                label: 'Bids',
                data: bids,
                borderColor: UP_COLOR,
                backgroundColor: UP_COLOR,
              },
              {
                label: 'Asks',
                data: asks,
                borderColor: DOWN_COLOR,
                backgroundColor: DOWN_COLOR,
              },
            ];
            // Remove old data to maintain a fixed window of 50 data points
            chart.update('none'); // Update chart without animations
          }
        });
      }
    }
  }, [JSON.stringify(analysisCriteria), symbol]);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Initialize Chart
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [], // Dynamic labels for timestamps
        datasets: [
          {
            label: 'Bids',
            data: [],
            backgroundColor: UP_COLOR,
          },
          {
            label: 'Asks',
            data: [],
            backgroundColor: DOWN_COLOR,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Size by Price',
          },
        },
        scales: {
          x: {
            type: 'timeseries',
            position: 'bottom',
            title: {
              display: true,
              text: 'Time',
              color: ticksColor,
            },
            ticks: {
              color: ticksColor, // Change the y-axis label color
            },
          },
          y: {
            title: {
              display: true,
              text: 'Price',
              color: ticksColor,
            },
            beginAtZero: false,
            ticks: {
              autoSkip: false, // Ensure all labels are shown
              color: ticksColor, // Change the y-axis label color
            },
          },
        },
      },
    });

    // Cleanup on unmount
    return () => {
      chartInstance.current.destroy();
    };
  }, []);

  return (
    <Card sx={{ height: '100%', width: '100%', p: 1 }}>
      <canvas style={{ height: '100%', width: '100%' }} ref={chartRef}></canvas>
    </Card>
  );
};

export default BidAskScatterLine;
