import Head from 'next/head';
import Footer from './Footer';
import styles from './Layout.module.css';
import Nav from './Nav';
import { useSelector } from 'react-redux';
import { selectTheme, selectUser } from 'store/appSlice';

const Layout = ({ children }) => {
  const user = useSelector(selectUser);
  const appTheme = useSelector(selectTheme);

  return (
    <>
      <Head>
        <title>Yours Tradely</title>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="description"
          content="Backtest, analyze, and optimize your trading strategies"
        />
        <meta property="og:title" content="Yours Tradely" />
        <meta
          property="og:description"
          content="Backtest, analyze, and optimize your trading strategies"
        />
        <meta property="og:image" content="" />
      </Head>
      <div id="application" data-theme={appTheme}>
        <div id="layout" className={styles.layout}>
          <Nav loggedIn={user.isValid} />
          <main className={user.isValid ? styles.main : ''}>{children}</main>
          {user.isValid && <Footer />}
        </div>
      </div>
    </>
  );
};

export default Layout;
