import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  IconButton,
  Icon,
  ListItemText,
} from '@mui/material';
import { ThemeSwitcher } from '@/components/ThemeSwitcher';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import styles from './Nav.module.css';
import Logo from '../../assets/logo.png';
import { navMenu } from 'config/navMenu';
import { MENU_CATEGORIES, MENU_TYPES, ROLES } from '@/lib/utils/constants';
import { FilePlus, LogOut } from 'react-feather';
import { createPage, getPages } from '@/lib/pages';
import { useDispatch, useSelector } from 'react-redux';
import { selectPages, selectUser, setPages, setUser } from 'store/appSlice';
import { usePathname } from 'next/navigation';
import { AdminPanelSettings, Close, Menu } from '@mui/icons-material';
import ListItemButton from '../ListItemButton';
import { signOut } from 'next-auth/react';
import { noop } from 'lodash';
// import StockEntry from 'widgets/StockEntry';

const MobileMenu = ({
  open = false,
  onClose = noop,
  items = [],
  isAdmin = false,
}) => {
  return (
    open && (
      <Box className={styles.mobileMenuContainer}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Link href="/">
              <Image
                className={styles.logo}
                src={Logo}
                alt="yours-tradely-logo"
              />
            </Link>
          </Box>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ marginTop: '1rem' }} onClick={onClose}>
          {items.map((menuList) => {
            return menuList.type === MENU_TYPES.MENU_LIST ? (
              <>
                <List className={styles.list}>
                  {menuList.items
                    .filter(({ hidden }) => !hidden)
                    .map(({ label, Icon, link, description }) => (
                      <ListItem
                        key={label}
                        disablePadding
                        sx={{
                          display: 'block',
                        }}
                      >
                        <Link passHref className={styles.link} href={link}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                            }}
                          >
                            <Box sx={{ color: 'var(--text-primary)' }}>
                              <Icon />
                            </Box>
                            <ListItemText
                              primary={label}
                              secondary={description}
                              classes={{
                                primary: styles.menuLabel,
                                secondary: styles.menuDescription,
                              }}
                            />
                          </Box>
                        </Link>
                      </ListItem>
                    ))}
                  {menuList.category === MENU_CATEGORIES.CUSTOM_PAGES && (
                    <ListItem
                      key={'New page'}
                      disablePadding
                      sx={{
                        display: 'block',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <Box sx={{ color: 'var(--text-primary)' }}>
                          <FilePlus />
                        </Box>
                        <ListItemText
                          primary={'New Page'}
                          secondary={'Create a new page and add widgets'}
                        />
                      </Box>
                    </ListItem>
                  )}
                </List>
              </>
            ) : menuList.type === MENU_TYPES.DIVIDER ? (
              <Divider />
            ) : (
              <></>
            );
          })}
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <List className={styles.list}>
              {isAdmin && (
                <ListItem
                  key={'admin-panel'}
                  disablePadding
                  sx={{
                    display: 'block',
                  }}
                >
                  <Link passHref className={styles.link} href={'/admin'}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      <Box sx={{ color: 'var(--text-primary)' }}>
                        <AdminPanelSettings />
                      </Box>
                      <ListItemText
                        primary={'Admin panel'}
                        secondary={'Internal YT admin page'}
                        classes={{
                          primary: styles.menuLabel,
                          secondary: styles.menuDescription,
                        }}
                      />
                    </Box>
                  </Link>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      </Box>
    )
  );
};

const Nav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const pages = useSelector(selectPages);
  const user = useSelector(selectUser);
  const loggedIn = !user.isLoading && user.isValid;
  const pathname = usePathname();
  const isAdmin = user?.role === ROLES.ADMIN;

  const createNewPageAndNavigate = async () => {
    const newPage = await createPage();
    dispatch(setPages([...pages, newPage]));
    router.push(`/custom/${newPage._id}`);
  };

  const _navMenu = navMenu(pages);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  const onSignOut = useCallback(async () => {
    try {
      const res = await signOut({ redirect: false });
      console.log(res);
      toast.success('You have been signed out');
      dispatch(setUser({ isLoading: false, isValid: false }));
      router.push('/login');
    } catch (e) {
      toast.error(e.message);
    }
  }, [router]);

  useEffect(() => {
    const fetchPages = async () => {
      const { pages: _pages } = await getPages();
      dispatch(setPages(_pages || []));
    };
    if (loggedIn) {
      fetchPages();
    }
  }, [loggedIn, dispatch]);

  return (
    <Box>
      {/* <Box
        sx={{
          width: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <StockEntry showFilingInsights={false} />
      </Box> */}
      {loggedIn && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            padding: '0.5rem',
          }}
        >
          <IconButton onClick={openMobileMenu}>
            <Menu />
          </IconButton>
        </Box>
      )}
      <div className={styles.themeSwitcher}>
        <ThemeSwitcher />
        {loggedIn && (
          <Box
            sx={{
              display: 'flex',
              flex: 1,
            }}
          >
            <List className={styles.list}>
              <ListItem
                key={'log-out'}
                disablePadding
                sx={{
                  display: 'block',
                }}
              >
                <IconButton onClick={onSignOut}>
                  <LogOut />
                </IconButton>
              </ListItem>
            </List>
          </Box>
        )}
      </div>
      {loggedIn && (
        <>
          <Drawer
            classes={{ paper: styles.sideBarContainer }}
            variant="permanent"
            open={false}
          >
            <List className={styles.list}>
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                }}
              >
                <Box className={styles.logoContainer}>
                  <Link href="/">
                    <Image
                      className={styles.logo}
                      src={Logo}
                      alt="yours-tradely-logo"
                    />
                  </Link>
                </Box>
              </ListItem>
            </List>
            {_navMenu.map((menuList) => {
              return menuList.type === MENU_TYPES.MENU_LIST ? (
                <>
                  <List className={styles.list}>
                    {menuList.items
                      .filter(({ hidden }) => !hidden)
                      .map(({ label, Icon, link, description }) => (
                        <ListItem
                          key={label}
                          disablePadding
                          sx={{
                            display: 'block',
                          }}
                          className={
                            pathname === link ? styles.selectedListItem : ''
                          }
                        >
                          <Link passHref className={styles.link} href={link}>
                            <ListItemButton
                              Icon={Icon}
                              showText={false}
                              label={label}
                              description={description}
                              selected={pathname === link}
                            />
                          </Link>
                        </ListItem>
                      ))}
                    {menuList.category === MENU_CATEGORIES.CUSTOM_PAGES && (
                      <ListItem
                        key={'New page'}
                        disablePadding
                        sx={{
                          display: 'block',
                        }}
                      >
                        <ListItemButton
                          Icon={FilePlus}
                          label={'New page'}
                          description={'Create a new page and add widgets'}
                          onClick={createNewPageAndNavigate}
                        />
                      </ListItem>
                    )}
                  </List>
                </>
              ) : menuList.type === MENU_TYPES.DIVIDER ? (
                <Divider />
              ) : (
                <></>
              );
            })}
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }}
            >
              <List className={styles.list}>
                {isAdmin && (
                  <ListItem
                    key={'admin-panel'}
                    disablePadding
                    sx={{
                      display: 'block',
                    }}
                    className={
                      pathname === '/admin' ? styles.selectedListItem : ''
                    }
                  >
                    <Link passHref className={styles.link} href={'/admin'}>
                      <ListItemButton
                        Icon={AdminPanelSettings}
                        label={'Admin panel'}
                        description={'Internal YT admin page'}
                        selected={pathname === '/admin'}
                      />
                    </Link>
                  </ListItem>
                )}
              </List>
            </Box>
          </Drawer>
          <MobileMenu
            open={mobileMenuOpen}
            onClose={closeMobileMenu}
            items={_navMenu}
            isAdmin={isAdmin}
          />
        </>
      )}
    </Box>
  );
};

export default Nav;
