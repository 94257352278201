import React, { useEffect, useRef } from 'react';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { cssVar } from '@/lib/utils';
import { selectStreamRawTrades } from 'store/streamSlice';
import { useSelector } from 'react-redux';
import Card from '@/components/Card';
import { selectTheme } from 'store/appSlice';
import _ from 'lodash';

// Register necessary components for the bar chart
Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const RealTimeTrades = ({ symbol = '' }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const bulkRawTrades = useSelector(selectStreamRawTrades);
  const appTheme = useSelector(selectTheme);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Initialize Chart
    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [], // Dynamic labels for timestamps
        datasets: [
          {
            data: [], // Dynamic data
            backgroundColor: cssVar('--text-primary'),
            barThickness: 10,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: true,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false, // Disable the legend
          },
        },
        scales: {
          xBottom: {
            position: 'bottom',
            title: {
              display: true,
              text: 'Size',
              color: cssVar('--text-primary'),
            },
            ticks: {
              color: cssVar('--text-primary'), // Change the y-axis label color
            },
          },
          xTop: {
            position: 'top',
            title: {
              display: true,
              text: 'Size',
              color: cssVar('--text-primary'),
            },
            ticks: {
              color: cssVar('--text-primary'), // Change the y-axis label color
            },
            grid: {
              drawOnChartArea: false, // Avoid duplicate gridlines
            },
          },
          y: {
            title: {
              display: true,
              text: 'price',
              color: cssVar('--text-primary'),
            },
            beginAtZero: false,
            ticks: {
              autoSkip: false, // Ensure all labels are shown
              color: cssVar('--text-primary'), // Change the y-axis label color
            },
          },
        },
      },
    });

    // Cleanup on unmount
    return () => {
      chartInstance.current.destroy();
    };
  }, []);

  // Update the chart theme
  useEffect(() => {
    const chart = chartInstance.current;
    const color = cssVar('--text-primary');
    // Check if the chart instance is available
    if (!chart) {
      return;
    }

    // Update the chart options
    const chartOptions = _.cloneDeep(chart.options);

    chart.options = {
      ...chartOptions, // Merge existing options
      // Apply new options
      scales: {
        x: {
          title: {
            color,
          },
          ticks: {
            color, // Change x-axis tick color
          },
        },
        y: {
          title: {
            color,
          },
          ticks: {
            color, // Change y-axis tick color
          },
        },
      },
    };

    chart.data.datasets[0] = {
      ...chart.data.datasets[0],
      backgroundColor: color,
    }; // Change the bar color

    // Apply the changes to the chart
    chart.update('none');
  }, [appTheme]);

  // Simulate real-time updates
  useEffect(() => {
    const _trades = [...bulkRawTrades].filter(
      (trade) => trade.Symbol === symbol
    );

    const labels = _trades.map((trade) => trade.Price).reverse();
    const sizes = _trades.map((trade) => trade.Size);

    const chart = chartInstance.current;
    if (chart) {
      const data = chart.data;
      data.labels = [...labels, ...data.labels]; // Add Processed Price
      data.datasets[0].data = [...sizes, ...data.datasets[0].data]; // Add size of trade
      // Remove old data to maintain a fixed window of 50 data points
      if (data.labels.length > 50) {
        data.labels = data.labels.slice(0, 50);
        data.datasets[0].data = data.datasets[0].data.slice(0, 50);
      }
      chart.update('none'); // Update chart without animations
    }
  }, [JSON.stringify(bulkRawTrades)]);

  return (
    <Card sx={{ height: '100%', width: '100%', p: 1 }}>
      <canvas style={{ height: '100%', width: '100%' }} ref={chartRef}></canvas>
    </Card>
  );
};

export default RealTimeTrades;
