import { useEffect, useRef, useState } from 'react';
import { EnhancedTable } from '@/components/Table';
import { stockFieldsConfig } from 'config/fields';
import { useDispatch, useSelector } from 'react-redux';
import { selectMomentumStocks } from 'store/streamSlice';
import {
  COLORS,
  CONTEXT_MENU_IDS,
  TABLE_CONTEXT_MENU,
} from '@/lib/utils/constants';
import { selectWatchlists, setWatchlists } from 'store/appSlice';
import { updateWatchlist } from '@/lib/watchlists';

const rightClickMenu = [TABLE_CONTEXT_MENU.ADD_TO_WATCHLIST];

const MomentumScanner = ({ onRowClick = () => {} }) => {
  const [config, setConfig] = useState([]);
  const dispatch = useDispatch();
  const watchlists = useSelector(selectWatchlists);

  const _onRowClick = (row, index) => {
    onRowClick(row);
  };

  const stocks = useSelector(selectMomentumStocks);

  // Handle right click menu item click
  const onMenuItemClick = async ({ id }, rowData) => {
    switch (id) {
      case CONTEXT_MENU_IDS.ADD_TO_WATCHLIST:
        const { Symbol } = rowData;
        const [watchlist] = _.cloneDeep(watchlists);
        watchlist.symbols.unshift(Symbol);
        await updateWatchlist(watchlist._id, { ...watchlist });
        dispatch(setWatchlists([watchlist, ...(watchlists.slice(1), [])]));
        break;
      default:
    }
  };

  useEffect(() => {
    setConfig({
      data: stocks,
      columns: [stockFieldsConfig.momentumStockSummary],
      rowBackgroundColor: ({ ClosePrice, OpenPrice }) => {
        return ClosePrice < OpenPrice
          ? COLORS.BACKGROUND_RED
          : COLORS.BACKGROUND_GREEN;
      },
    });
  }, [stocks.length]);

  return (
    <EnhancedTable
      heading="Alerts"
      config={config}
      menuItems={rightClickMenu}
      onMenuItemClick={onMenuItemClick}
      onRowClick={_onRowClick}
      highlightItems={false}
    />
  );
};

export default MomentumScanner;
