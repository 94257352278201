import { Box, Modal as MUIModal, Typography } from '@mui/material';
import { Button } from '@/components/Button';
import styles from './Modal.module.css';
import { useEffect, useState } from 'react';

export const Modal = ({
  open,
  onClose = () => {},
  onProceed = () => {},
  variant = '',
  children,
}) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    // Access the container only on the client side
    const appContainer = document.getElementById('application');
    setContainer(appContainer);
  }, []);

  return !container ? null : (
    <MUIModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ root: styles.modalContainer }}
      container={container}
    >
      {variant === 'delete' ? (
        <Box className={styles.modalContent}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete ?
          </Typography>
          <Box className={styles.actionsContainer}>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={onProceed} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      ) : (
        children
      )}
    </MUIModal>
  );
};
