import { Box, CircularProgress } from '@mui/material';
import styles from './Spinner.module.css';

export const Spinner = ({ type = '' }) => {
  return (
    <Box className={styles.spinnerContainer}>
      {type === 'circular' ? (
        <CircularProgress />
      ) : (
        <div className={styles.gridLoaderContainer}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
      )}
    </Box>
  );
};
