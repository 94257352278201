import React, { useState, useEffect, useRef, useCallback } from 'react';
import FinancialChart from './FinancialChart';
import { getStockData } from '@/lib/stock';
import {
  convertAlpacaDataToFinancialChartData,
  getAfterHoursCloseDateTime,
  getAfterHoursCloseDateTimeInCurrentTimezone,
  getNextTimestamp,
  getPremarketOpenDateTimeInCurrentTimezone,
} from '@/lib/utils';
import { Box, Divider } from '@mui/material';
import MoreMenu from './MoreMenu';
import { CONFIG_TYPES, TIME, TIMEFRAMES } from '@/lib/utils/constants';
import { useSelector } from 'react-redux';
import { selectStreamTrades } from 'store/streamSlice';
import _ from 'lodash';
import { LightChart } from '../LightChart/Chart';
import moment from 'moment-timezone';
import Card from '../Card';

const ChartWrapper = ({ config = {}, lightChart = true, setChartConfig }) => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    volumeProfile: false,
    deltaTooltip: false,
  });
  const rawData = useRef([]);
  const trades = useSelector(selectStreamTrades);
  const previousSelectedDays = useRef(null);

  const updateOptions = (updatedOptions) => {
    setChartOptions((options) => ({
      ...options,
      ...updatedOptions,
    }));
  };

  const updateChartConfig = (payload) => {
    const { type, value } = payload;

    const { to, from } = config;

    switch (type) {
      case CONFIG_TYPES.NEXT_DAY:
        const nextDate = getNextTimestamp(to, 1, TIME.DATE);
        setChartConfig({
          ...config,
          to: nextDate,
        });
        break;
      case CONFIG_TYPES.PREVIOUS_DAY:
        const previousDate = getNextTimestamp(from, -1, TIME.DATE);
        setChartConfig({
          ...config,
          from: previousDate,
        });
        break;
      case CONFIG_TYPES.SELECTED_DAY_RANGE:
        const { startDate, endDate, timeframe = config.timeframe } = value;
        setChartConfig({
          ...config,
          from: getPremarketOpenDateTimeInCurrentTimezone(startDate),
          to: getAfterHoursCloseDateTimeInCurrentTimezone(endDate),
          timeframe,
        });
        break;
      case CONFIG_TYPES.TIMEFRAME:
        if (value === TIMEFRAMES.DAYS_1) {
          const from = getNextTimestamp(moment(), -2, TIME.YEAR);
          const to = getAfterHoursCloseDateTime(moment());

          previousSelectedDays.current = {
            from: config.from,
            to: config.to,
          };

          setChartConfig({
            ...config,
            from,
            to,
            timeframe: value,
          });
        } else {
          setChartConfig((config) => ({
            ...config,
            timeframe: value,
          }));
        }

        break;
    }
  };

  const processForMissingCandles = () => {
    const _rawConvertedData = convertAlpacaDataToFinancialChartData(
      rawData.current
    );

    let finalData = _rawConvertedData;

    // Process for missing candle only when timeframe is 1Min
    if (config.timeframe === TIMEFRAMES.MINS_1) {
      finalData = [];

      for (let i = 0; i < _rawConvertedData.length - 1; i++) {
        const current = _rawConvertedData[i];
        const next = _rawConvertedData[i + 1];
        const currentTimeInMs = new Date(current.date).getTime();
        const nextTimeInMs = new Date(next.date).getTime();

        if (
          nextTimeInMs - currentTimeInMs === 60 * 1000 ||
          current.volume < 5000
        ) {
          finalData.push(current);
        } else {
          let newCurrent = {
            ...current,
          };

          let newCurrentTimeInMs = new Date(newCurrent.date).getTime();

          while (nextTimeInMs - newCurrentTimeInMs !== 60 * 1000) {
            finalData.push(newCurrent);

            newCurrent = {
              date: new Date(
                new Date(newCurrent.date).setMinutes(
                  new Date(newCurrent.date).getMinutes() + 1
                )
              ),
              open: newCurrent.close,
              high: newCurrent.close,
              low: newCurrent.close,
              close: newCurrent.close,
              volume: 0,
              originalTimestamp: newCurrent.originalTimestamp,
            };

            newCurrentTimeInMs = new Date(newCurrent.date).getTime();
          }
          finalData.push(newCurrent);
        }

        if (i === _rawConvertedData.length - 2) {
          finalData.push(_rawConvertedData[_rawConvertedData.length - 1]);
        }
      }
    }

    setChartData({
      results: finalData,
      lightChartResults: finalData.map(
        ({ open, high, low, close, volume, date }) => ({
          open,
          close,
          high,
          low,
          volume,
          time: date.getTime() / 1000,
        })
      ),
      hasResults: true,
      symbol: config.symbol,
    });
  };

  const fetchData = async () => {
    try {
      const res = await getStockData(config);
      rawData.current = res || [];
      processForMissingCandles();
    } catch (err) {
      setChartData({
        results: [],
        lightChartResults: [],
        hasResults: false,
        symbol: config.symbol,
      });
    }
  };

  const throttledChartDataUpdate = useCallback(
    _.throttle((trades) => {
      setChartData((_chartData) => {
        const _results = _chartData.results;
        const lastCandle = _results[_results.length - 1];

        const currentPrice = trades[_chartData.symbol];

        if (currentPrice) {
          if (currentPrice > lastCandle.high) {
            lastCandle.high = currentPrice;
          }

          if (currentPrice < lastCandle.low) {
            lastCandle.low = currentPrice;
          }

          lastCandle.close = currentPrice;
        }

        return {
          results: _results,
          hasResults: true,
          symbol: _chartData.symbol,
        };
      });
    }, 5000),
    []
  );

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(config)]);

  useEffect(() => {
    if (chartData.symbol) {
      // throttledChartDataUpdate(trades);
    }
  }, [JSON.stringify(trades)]);

  return (
    <Card
      sx={{
        flex: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box>
        <MoreMenu
          updateOptions={updateOptions}
          updateChartConfig={updateChartConfig}
          refreshData={fetchData}
          chartConfig={config}
        />
      </Box>
      <Divider />
      <Box sx={{ flex: 1, position: 'relative' }}>
        <LightChart
          data={chartData?.lightChartResults || []}
          symbol={chartData?.symbol || ''}
          volumeProfile={chartOptions.volumeProfile}
          deltaTooltip={chartOptions.deltaTooltip}
          timeframe={config.timeframe}
          updateChartConfig={updateChartConfig}
        />
        {!chartData?.hasResults && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '90%',
              width: '100%',
              flex: 1,
              position: 'absolute',
              top: 0,
              zIndex: 999,
              color: 'var(--text-secondary)',
            }}
          >
            Select a Stock to see the chart
          </Box>
        )}
        {/* {chartData?.hasResults ? (
          lightChart ? (
            <LightChart
              data={chartData?.lightChartResults || []}
              symbol={chartData?.symbol || ''}
              volumeProfile={chartOptions.volumeProfile}
              deltaTooltip={chartOptions.deltaTooltip}
              timeframe={config.timeframe}
              updateChartConfig={updateChartConfig}
            />
          ) : (
            <FinancialChart
              data={chartData.results}
              symbol={chartData.symbol}
              options={chartOptions}
            />
          )
        ) : (
          <></>
        )} */}
      </Box>
    </Card>
  );
};

export default ChartWrapper;
