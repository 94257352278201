import { Box, IconButton } from '@mui/material';
import {
  createChart,
  ColorType,
  CrosshairMode,
  LineStyle,
} from 'lightweight-charts';
import { noop } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { SessionHighlighting } from '../Chart/plugins/session-highlighting/session-highlighting';
import { subscribeForTrades, unsubscribeFromTrades } from '@/lib/stream';
import { useDispatch, useSelector } from 'react-redux';
import { selectStreamRawTrades } from 'store/streamSlice';
import { CustomTooltipPrimitive } from '../Chart/plugins/custom-tooltip/tooltip';
import { marketHoursInLocalTZ } from '@/lib/utils/date';
import { DeltaTooltipPrimitive } from '../Chart/plugins/delta-tooltip/delta-tooltip';
import { Menu, MenuItem } from '../Menu';
import { setSelectedCriteriaForAnalysis } from 'store/analysisSlice';
import { cssVar } from '@/lib/utils';
import { CONFIG_TYPES, TIMEFRAMES } from '@/lib/utils/constants';
import ToggleButtonGroup from '../ToggleButtonGroup';
import { Close, HorizontalRule, LineAxis } from '@mui/icons-material';
import styles from './Chart.module.css';
import {
  createChartMetadata,
  deleteChartMetadata,
  getChartMetadataBySymbol,
} from '@/lib/metadata/chart';

const localTimezoneOffset = new Date().getTimezoneOffset() * 60;

const deltaTooltipPrimitive = new DeltaTooltipPrimitive({
  lineColor: 'rgba(0, 0, 0, 0.2)',
});

const timeZoned = (data) =>
  data.map((d) => ({
    ...d,
    time: d.time - localTimezoneOffset,
  }));

export const LightChart = (props) => {
  const {
    data,
    colors: {
      backgroundColor = 'white',
      lineColor = '#2962FF',
      textColor = 'black',
      areaTopColor = '#2962FF',
      areaBottomColor = 'rgba(41, 98, 255, 0.28)',
    } = {},
    symbol,
    attachChartInstance = noop,
    deltaTooltip = false,
    timeframe = TIMEFRAMES.MINS_1,
    updateChartConfig = noop,
  } = props;

  const UP_COLOR = cssVar('--price-move-up-text');
  const DOWN_COLOR = cssVar('--price-move-down-text');

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [clickedData, setClickedData] = useState(null);

  const [priceLines, setPriceLines] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [activeLine, setActiveLine] = useState(null);
  const [_activeDrawingTool, setActiveDrawingTool] = useState(null);
  const previousSymbol = useRef(null);
  const trades = useSelector(selectStreamRawTrades);
  const timezonedData = timeZoned(data);
  const candleSeriesRef = useRef(null);
  const volumeSeriesRef = useRef(null);
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);
  const currentCandleStick = useRef({
    open: 0,
    high: 0,
    low: 0,
    close: 0,
  });

  const chartContainerRef = useRef();
  const activeDrawingTool = useRef(null);

  const dispatch = useDispatch();

  const resetCurrentCandleStick = () => {
    const _candle = {
      ...currentCandleStick.current,
    };
    _candle.close = 0;
    _candle.open = 0;
    _candle.high = 0;
    _candle.low = 0;

    currentCandleStick.current = _candle;
  };

  const updatedCurrentCandleStick = (price) => {
    const _candle = {
      ...currentCandleStick.current,
    };
    const _time = Math.floor(Date.now() / 1000 / 60) * 60 - localTimezoneOffset;

    if (currentCandleStick.current.time !== _time) {
      _candle.close = 0;
      _candle.open = 0;
      _candle.high = 0;
      _candle.low = 0;
    }

    if (!_candle.open) {
      _candle.open = price;
    }

    if (!_candle.close) {
      _candle.close = price;
    }

    if (!_candle.high) {
      _candle.high = price;
    }

    if (!_candle.low) {
      _candle.low = price;
    }

    if (price > _candle.high) {
      _candle.high = price;
    }

    if (price < _candle.low) {
      _candle.low = price;
    }

    _candle.close = price;

    _candle.time = _time;

    currentCandleStick.current = _candle;

    return _candle;
  };

  // Subscribe to right-click events
  const handleContextMenu = (event) => {
    event.preventDefault();
    const rect = chartContainerRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // const point = candleSeriesRef.current.priceScale().coordinateToPrice(x);
    const time = chartRef.current.timeScale().coordinateToTime(x);

    if (time) {
      const reconstrucedTimeInMs = (time + localTimezoneOffset) * 1000;
      setClickedData({ time: new Date(reconstrucedTimeInMs) });
      setMenuAnchor({
        mouseX: event.clientX,
        mouseY: event.clientY,
      });
    }
  };

  const updateSelectedCandleTime = () => {
    dispatch(
      setSelectedCriteriaForAnalysis({
        from: clickedData.time,
        to: clickedData.time,
      })
    );

    handleContextMenuClose();
  };

  const _updateChartConfig = () => {
    updateChartConfig({
      type: CONFIG_TYPES.SELECTED_DAY_RANGE,
      value: {
        startDate: clickedData.time,
        endDate: clickedData.time,
        timeframe: TIMEFRAMES.MINS_1,
      },
    });
    handleContextMenuClose();
  };

  //handle context menu close
  const handleContextMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleDrawingToolChange = (event, value) => {
    setActiveDrawingTool(value);
    activeDrawingTool.current = value;
  };

  // Horizontal price lines implementation
  const addPriceLine = async (price) => {
    const color = getRandomColor();
    // const title = `Price Line ${priceLines.length + 1}`;
    const title = '';
    const line = candleSeriesRef.current.createPriceLine({
      price,
      color,
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      axisLabelVisible: true,
      title,
    });

    // Persist line
    const { _id } = await createChartMetadata(
      previousSymbol.current,
      price,
      color,
      title
    );
    setPriceLines((prevLines) => [...prevLines, { id: _id, line, price }]);
  };

  const removePriceLine = (id) => {
    const lineToRemove = priceLines.find((pl) => pl.id === id);
    if (lineToRemove) {
      candleSeriesRef.current.removePriceLine(lineToRemove.line);
      setPriceLines((prevLines) => prevLines.filter((pl) => pl.id !== id));
      deleteChartMetadata(id);
    }
  };

  const handleMouseDown = (param) => {
    // Horizontal Price line implementation
    const horizontalLine = activeDrawingTool.current === 'horizontal-line';
    if (horizontalLine) {
      if (!param.point) return;
      const clickedPrice = candleSeriesRef.current.coordinateToPrice(
        param.point.y
      );

      if (clickedPrice) {
        const line = priceLines.find(
          (pl) => Math.abs(pl.price - clickedPrice) < 1 // Adjust sensitivity as needed
        );
        if (line) {
          setActiveLine(line);
          setIsDragging(true);
        } else {
          addPriceLine(clickedPrice);
        }
      }
    }
  };

  const handleMouseMove = (param) => {
    if (!isDragging || !param.point || !activeLine) return;

    const newPrice = candleSeriesRef.current.coordinateToPrice(param.point.y);
    if (newPrice !== undefined) {
      setPriceLines((prevLines) =>
        prevLines.map((pl) =>
          pl === activeLine
            ? {
                line: candleSeriesRef.current.createPriceLine({
                  ...pl.line.options(),
                  price: newPrice,
                }),
                price: newPrice,
              }
            : pl
        )
      );
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setActiveLine(null);
  };

  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')}`;
  };

  const _removePriceLines = () => {
    priceLines.forEach(({ line }) => {
      candleSeriesRef.current.removePriceLine(line);
    });
    setPriceLines([]);
  };

  const _fetchChartMetadata = async (symbol) => {
    // fetch chart metadata
    // Remove previous symbol price lines
    _removePriceLines();

    const { metadata = [] } = await getChartMetadataBySymbol(symbol);
    if (metadata.length) {
      metadata.forEach(({ price, color, title, _id }) => {
        const line = candleSeriesRef.current.createPriceLine({
          price,
          color,
          lineWidth: 1,
          lineStyle: LineStyle.Solid,
          axisLabelVisible: true,
          title,
        });

        setPriceLines((prevLines) => [...prevLines, { id: _id, line, price }]);
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      chartRef.current.applyOptions({
        width: chartContainerRef.current.clientWidth,
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
      });
    };

    chartRef.current = createChart(chartContainerRef.current, {
      crosshair: {
        // Change mode from default 'magnet' to 'normal'.
        // Allows the crosshair to move freely without snapping to datapoints
        mode: CrosshairMode.Normal,
      },
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
      priceScaleId: 'left',
      scaleMargins: {
        top: 0.9, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });

    //subscribe to events
    _subscribeEvents(chartRef.current);

    if (attachChartInstance) {
      attachChartInstance(chartRef.current);
    }

    const candlestickSeries = chartRef.current.addCandlestickSeries({
      upColor: UP_COLOR,
      downColor: DOWN_COLOR,
      borderVisible: false,
      wickUpColor: UP_COLOR,
      wickDownColor: DOWN_COLOR,
    });

    chartRef.current.applyOptions({
      watermark: {
        visible: true,
        fontSize: 64,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(0, 112, 243, 0.2)',
        text: symbol,
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
      priceScaleId: 'left',
    });

    candleSeriesRef.current = candlestickSeries;

    const volumeSeries = chartRef.current.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: 'left', // set as an overlay by setting a blank priceScaleId
      // set the positioning of the volume series
      scaleMargins: {
        top: 0.7, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });

    volumeSeries.priceScale('left').applyOptions({
      scaleMargins: {
        top: 0.9, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });

    volumeSeriesRef.current = volumeSeries;

    // Pre and post market session highlighting
    const sessionHighlighter = (time) => {
      const date = new Date(time * 1000);

      const hours = date.getUTCHours(); // 0 - 23
      const minutes = date.getUTCMinutes(); // 0 - 59

      if (
        hours >= marketHoursInLocalTZ.preMarketOpen &&
        hours <= marketHoursInLocalTZ.postMarketClose
      ) {
        if (
          hours < marketHoursInLocalTZ.regOpen ||
          (hours === marketHoursInLocalTZ.regOpen && minutes <= 30)
        ) {
          // Premarket 4:00 - 9:30 EST
          return 'rgba(255, 152, 1, 0.08)';
        }

        if (
          hours >= marketHoursInLocalTZ.regClose &&
          hours <= marketHoursInLocalTZ.postMarketClose
        ) {
          // Post market hours 16:00 - 20:00 EST
          return 'rgba(41, 98, 255, 0.08)';
        }
      }
    };

    const sessionHighlighting = new SessionHighlighting(sessionHighlighter);
    candlestickSeries.attachPrimitive(sessionHighlighting);

    chartRef.current.timeScale().fitContent();

    window.addEventListener('resize', handleResize);

    // Subscribe to right click events trading view light weight chart
    chartContainerRef.current.addEventListener(
      'contextmenu',
      handleContextMenu
    );

    return () => {
      window.removeEventListener('resize', handleResize);
      chartRef.current.remove();
    };
  }, [backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]);

  useEffect(() => {
    // Update watermark with symbol
    if (chartRef.current) {
      chartRef.current.applyOptions({
        watermark: {
          text: symbol,
        },
      });
    }

    // Update candlestick series with new data
    if (candleSeriesRef.current) {
      candleSeriesRef.current.setData(timezonedData);
    }

    // Update volume series with new data
    if (volumeSeriesRef.current) {
      volumeSeriesRef.current.setData(
        timezonedData.map(({ volume, time, close, open }) => ({
          time,
          value: volume,
          color: close > open ? UP_COLOR : DOWN_COLOR,
        }))
      );
    }
    // Fit content
    chartRef.current.timeScale().fitContent();
  }, [symbol, data]);

  useEffect(() => {
    const lastTrade = trades
      .filter((trade) => trade.Symbol === previousSymbol.current)
      .pop();
    if (lastTrade) {
      if (candleSeriesRef.current) {
        try {
          candleSeriesRef.current.update(
            updatedCurrentCandleStick(lastTrade.Price)
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  }, [trades]);

  //Attach tooltip
  useEffect(() => {
    if (candleSeriesRef.current && chartRef.current) {
      if (deltaTooltip) {
        //Lock scroll
        chartRef.current.applyOptions({
          handleScroll: false,
        });

        if (tooltipRef.current) {
          tooltipRef.current.destroy();
        }

        candleSeriesRef.current.attachPrimitive(deltaTooltipPrimitive);
      } else {
        //Unlock scroll
        chartRef.current.applyOptions({
          handleScroll: true,
          crosshair: {
            mode: CrosshairMode.Normal,
            vertLine: {
              visible: true,
              labelVisible: true,
            },
            horzLine: {
              visible: true,
              labelVisible: true,
            },
          },
        });
        if (deltaTooltipPrimitive) {
          candleSeriesRef.current.detachPrimitive(deltaTooltipPrimitive);
        }
        // Create and attach the custom tooltip plugin
        tooltipRef.current = new CustomTooltipPrimitive(
          chartRef.current,
          candleSeriesRef.current,
          volumeSeriesRef.current,
          {
            tooltipBgColor: 'rgba(255, 255, 255, 0.9)',
            tooltipTextColor: '#000',
            borderRadius: '8px',
          },
          timeframe
        );
      }
    }
  }, [deltaTooltip, symbol, timeframe]);

  // useEffect(() => {
  //   if (trades[previousSymbol.current]) {
  //     if (chartRef.current) {
  //       chartRef.current.update(
  //         updatedCurrentCandleStick(trades[previousSymbol.current])
  //       );
  //     }
  //   }
  // }, [bars]);

  useEffect(() => {
    if (symbol) {
      if (previousSymbol.current !== symbol) {
        previousSymbol.current &&
          unsubscribeFromTrades([previousSymbol.current]);
        resetCurrentCandleStick();
        subscribeForTrades([symbol]);
        previousSymbol.current = symbol;
      }

      _fetchChartMetadata(symbol);
    }
  }, [symbol]);

  const _unsubscribeEvents = (chart) => {
    if (!chart) return;
    chart.unsubscribeClick(handleMouseDown);
    chart.unsubscribeCrosshairMove(handleMouseMove);
    chart.unsubscribeCrosshairMove(handleMouseUp);
  };

  const _subscribeEvents = (chart) => {
    if (!chart) return;
    chart.subscribeClick(handleMouseDown);
    chart.subscribeCrosshairMove(handleMouseMove);
    chart.subscribeCrosshairMove(handleMouseUp);
  };

  // Clean up
  useEffect(() => {
    return () => {
      previousSymbol.current && unsubscribeFromTrades([previousSymbol.current]);
      previousSymbol.current = null;
      const chart = chartRef.current;
      if (!chart) return;
      _unsubscribeEvents(chart);
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '2rem',
          backgroundColor: 'var(--card-bg)',
          borderRight: '1px solid var(--active-bg)',
          position: 'absolute',
          margin: '1rem 0.5rem',
          zIndex: 999,
        }}
      >
        <ToggleButtonGroup
          selected={_activeDrawingTool}
          onChange={handleDrawingToolChange}
          aria-label="Line options"
          size="small"
          color="primary"
          classes={{ root: styles.buttonGroup }}
          verticalAlign
          buttons={[
            {
              name: <HorizontalRule />,
              value: 'horizontal-line',
            },
            {
              name: <LineAxis />,
              value: 'ray-line',
            },
          ]}
        ></ToggleButtonGroup>
      </Box>
      <Box
        sx={{ height: '100%', flex: 1 }}
        ref={chartContainerRef}
        onClick={handleContextMenuClose}
      />
      <Menu
        container={document.getElementById('application')}
        open={Boolean(menuAnchor)}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          menuAnchor
            ? { top: menuAnchor.mouseY, left: menuAnchor.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={updateSelectedCandleTime}>
          Drill into this Candle
        </MenuItem>
        {timeframe === TIMEFRAMES.DAYS_1 && (
          <MenuItem onClick={_updateChartConfig}>Go to this day</MenuItem>
        )}
      </Menu>
      <Box>
        {priceLines.map((pl) => (
          <div
            key={pl.id}
            style={{
              position: 'absolute',
              left: '5px',
              top: `${
                candleSeriesRef.current.priceToCoordinate(pl.price) - 10
              }px`,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            {/* <span>{pl.text}</span> */}
            <Box
              onClick={() => removePriceLine(pl.id)}
              sx={{
                background: 'red',
                color: 'white',
                border: 'none',
                width: '24px',
                height: '24px',
                cursor: 'pointer',
                zIndex: 999,
              }}
            >
              <Close />
            </Box>
          </div>
        ))}
      </Box>
    </Box>
  );
};
