import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/appSlice';
import Spinner from './Spinner';
import { useSession } from 'next-auth/react';

export const withAuth = (Component) => {
  return function AuthenticatedComponent(props) {
    const { data: session, status } = useSession();
    const dispatch = useDispatch();

    if (session?.user) {
      dispatch(setUser({ ...session.user, isLoading: false, isValid: true }));
    }

    const user = {
      isLoading: status === 'loading',
      isValid: !!session?.user?.email,
    };

    const router = useRouter();
    const [showSpinner, setShowSpinner] = useState(user.isLoading);

    useEffect(() => {
      const handleStart = () => {
        setShowSpinner(true);
      };
      const handleComplete = () => {
        setShowSpinner(false);
      };

      router.events.on('routeChangeStart', handleStart);
      router.events.on('routeChangeComplete', handleComplete);
      router.events.on('routeChangeError', handleComplete);

      return () => {
        router.events.off('routeChangeStart', handleStart);
        router.events.off('routeChangeComplete', handleComplete);
        router.events.off('routeChangeError', handleComplete);
      };
    }, [router]);

    useEffect(() => {
      if (!user.isLoading) {
        if (user.isValid) {
          setShowSpinner(false);
        } else {
          router.push('/login');
        }
      }
    }, [JSON.stringify(user), status]);

    if (showSpinner) {
      return <Spinner />;
    }

    return <Component {...props} />;
  };
};
