import { WidgetIdToComponent } from 'config/widgets';

export * from './date';
export * from './type';
export * from './stock';
export * from './number';

export const processedPayload = (payload) => {
  const _payload = { ...payload };
  delete _payload._id;
  return JSON.stringify(_payload);
};

export const serializeRGLayoutToStoredLayout = (rgLayoutItem, localLayout) => {
  return {
    ...rgLayoutItem,
    type: localLayout.type,
    props: {
      ...(localLayout.props || {}),
    },
  };
};

export const deserializeRGLayoutFromStoredLayout = (storedLayout) => {
  const { id, type, props, x = 0, y = 0, h, w } = storedLayout;
  const widgetConfig = WidgetIdToComponent[id] || WidgetIdToComponent[type];

  return {
    i: id ? id : widgetConfig?.id(type, props),
    WidgetComponent: widgetConfig.Component,
    h: h ?? widgetConfig.height,
    w: w ?? widgetConfig.width,
    x,
    y,
    type: type,
    props: {
      ...(props || {}),
    },
    defaultProps: widgetConfig.defaultProps || [],
  };
};

export const cssVar = (name) => {
  return getComputedStyle(
    document.getElementById('application')
  ).getPropertyValue(name);
};
