import { fetcher } from '../fetch';

export const createScanner = async (scanner) => {
  return await fetcher('/api/scanners', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...scanner }),
  });
};

export const getScanners = async () => {
  return await fetcher(`/api/scanners`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getScannerById = async (scannerId) => {
  return await fetcher(`/api/scanners/${scannerId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const deleteScanner = async (id) => {
  return await fetcher('/api/scanners', {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};

export const runInlineScanner = async (params) => {
  return await fetcher('/api/scanners/inline', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ criteria: params }),
  });
};
