import { fetcher } from '../fetch';

export const createDatapoint = async (datapoint) => {
  return await fetcher('/api/datapoints', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...datapoint }),
  });
};

export const getDatapoints = async () => {
  return await fetcher(`/api/datapoints`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const deleteDatapoint = async (id) => {
  return await fetcher('/api/datapoints', {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
};
